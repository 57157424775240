import { IConfig } from "~/types/config.interface";
import { Countries, ILocales } from "~/types/entities";

const STORE_NAMESPACE = "config";

export enum UserTypes {
  Companies = "companies",
  Partners = "partners",
  Users = "users",
}

export const userTypes = Object.entries(UserTypes).map(([_, value]) => ({
  value,
  id: value,
  path: "/" + (UserTypes.Companies === value ? "" : value),
}));

export const useConfigStore = defineStore(STORE_NAMESPACE, {
  state(): IConfig {
    return {
      system: {
        build: process.env.NUXT_BITBUCKET_BUILD_NUMBER || "–",
      },
    };
  },

  getters: {
    countryCode(): keyof typeof Countries {
      const { locale, locales } = useI18n();
      return locales.value.find(
        (localeItem: ILocales) => localeItem.code === locale.value
      ).country;
    },
  },
  actions: {},
});
